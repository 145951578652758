import React from 'react';
import '../componentsStyles/Footer.css';
import paymentMethods from '../assets/images/payment-methods.png'; // Görselin dosya yolunu buraya ekleyin

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-column">
          <h4>AWL Eloboost</h4>
          <ul>
            <li><a href="#contact">İletişim</a></li>
            <li><a href="#customer-reviews">Müşteri yorumları</a></li>
            <li><a href="#join-team">Ekibimize Katıl</a></li>
            <li><a href="#become-agent">Bize Katıl</a></li>
            <li><a href="#corporate">Kurumsal</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Yardım</h4>
          <ul>
            <li><a href="#faq">Sıkça Sorulan Sorular</a></li>
            <li><a href="#account-create">Hesap Açmak İstiyorum</a></li>
            <li><a href="#account-buy">Hesap Satmak İstiyorum</a></li>
            <li><a href="#balance-withdraw">Bakiye Yükleme/Çekme</a></li>
            <li><a href="#create-request">Talep Oluştur</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Üyelik</h4>
          <ul>
            <li><a href="#terms">Kullanım Sözleşmesi</a></li>
            <li><a href="#privacy">Gizlilik Politikası</a></li>
            <li><a href="#sales">Satış Sözleşmesi</a></li>
            <li><a href="#kvkk">KVKK Aydınlatma Metni</a></li>
            <li><a href="#consent">KVKK Açık Rıza Metni</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Favori</h4>
          <ul>
            <li><a href="#valorant">Valorant Hesap Satın Al</a></li>
            <li><a href="#lol">Lol. Hesap Satın Al</a></li>
            <li><a href="#valorant-points">Valorant Points Satın Al</a></li>
            <li><a href="#lol-riot-points">Lol Riot Points satın al</a></li>
            <li><a href="#wild-rift">Wild Rift Hesap Satın Al</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Sosyal Medya</h4>
          <ul>
            <li><a href="#instagram">Instagram</a></li>
            <li><a href="#tiktok">TikTok</a></li>
            <li><a href="#youtube">Youtube</a></li>
            <li><a href="#twitter">Twitter</a></li>
            <li><a href="#twitch">Twitch</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>©2024 WatcherS Company</p>
        <p>AWLEloboost.com'daki tüm isimler, logolar, resimler, ürünler, ticari markalar, ilgili sahiplerinin telif hakkıdır ve sahiplerinin mülkiyetindedir. Kullanılan telif hakları, ticari amaçlarla (kâr vb.) dışarıya tanıtım/üretim (reklam) tanınmasına amacıyla kullanılmaktadır.</p>
        <img src={paymentMethods} alt="Ödeme Yöntemleri" />
      </div>
    </footer>
  );
};

export default Footer;
