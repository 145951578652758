// src/components/LoginSignupModal.js
import React, { useState } from 'react';
import '../componentsStyles/Auth.css';

const Login = ({ isOpen, onClose }) => {
  const [isLogin, setIsLogin] = useState(true);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <button className="close-button" onClick={onClose}>X</button>
        <div className="form-toggle">
          <button onClick={() => setIsLogin(true)} className={isLogin ? 'active' : ''}>Giriş Yap</button>
          <button onClick={() => setIsLogin(false)} className={!isLogin ? 'active' : ''}>Üye Ol</button>
        </div>
        {isLogin ? (
          <div className="login-form">
            <h2>Giriş Yap</h2>
            <form>
              <label>Email:</label>
              <input type="email" required />
              <label>Şifre:</label>
              <input type="password" required />
              <button type="submit">Giriş Yap</button>
            </form>
          </div>
        ) : (
          <div className="signup-form">
            <h2>Üye Ol</h2>
            <form>
              <label>Email:</label>
              <input type="email" required />
              <label>Şifre:</label>
              <input type="password" required />
              <label>Şifre Tekrar:</label>
              <input type="password" required />
              <button type="submit">Üye Ol</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
