import React from 'react';
import '../pagesStyles/BoostingPage.css';
import Footer from '../components/Footer'; // Yeni eklenen import
import Header from '../components/Header';



const BoostingPage = () => {
  return (
    <div className="boosting-page">
                    <div className="Header">

<Header />

</div>
      <div className="boost-section">
        <div className="boost-card">
          <div className="boost-card-header">
            <img src="mevcut-lig.png" alt="Mevcut Lig" className="lig-icon" />
            <h3>Mevcut Lig</h3>
          </div>
          <select className="boost-select">
            <option>Gümüş</option>
          </select>
          <select className="boost-select">
            <option>Aşama 1</option>
          </select>
          <div className="boost-lp">
            <label>Mevcut LP</label>
            <select className="boost-select">
              <option>0-20</option>
            </select>
          </div>
        </div>
        
        <div className="boost-card">
          <div className="boost-card-header">
            <img src="istenilen-lig.png" alt="İstenilen Lig" className="lig-icon" />
            <h3>İstenilen Lig</h3>
          </div>
          <select className="boost-select">
            <option>Master</option>
          </select>
          <div className="boost-lp">
            <label>LP Kazanç</label>
            <select className="boost-select">
              <option>10-19</option>
            </select>
          </div>
        </div>
        
        <div className="boost-card">
          <div className="boost-card-header">
            <img src="boost-ozellestirme.png" alt="Boost Özelleştirme" className="lig-icon" />
            <h3>Boost Özelleştirme</h3>
          </div>
          <div className="boost-options">
            <div className="boost-option">
              <label>Flash tuşu seçimi <span className="ucretsiz">ücretsiz</span></label>
              <input type="checkbox" />
            </div>
            <div className="boost-option">
              <label>Oyun sonu +1 win <span className="ucretsiz">ücretsiz</span></label>
              <input type="checkbox" />
            </div>
            <div className="boost-option">
              <label>Çevrim dışı oyun modu <span className="ucretsiz">ücretsiz</span></label>
              <input type="checkbox" />
            </div>
            <div className="boost-option">
              <label>Öncelikli işlem (acil) <span className="ucretli">+15%</span></label>
              <input type="checkbox" />
            </div>
            <div className="boost-option">
              <label>Boost sonu garanti 1 hafta <span className="ucretli">+25%</span></label>
              <input type="checkbox" />
            </div>
            <div className="boost-option">
              <label>Canlı Yayın <span className="ucretli">+20%</span></label>
              <input type="checkbox" />
            </div>
            <div className="boost-option">
              <label>Karakter / Rol Seçme <span className="ucretli">+25%</span></label>
              <input type="checkbox" />
            </div>
          </div>
        </div>
      </div>
      
      <div className="server-section">
        <div className="server-card">
          <label>Server</label>
          <select className="server-select">
            <option>Türkiye</option>
          </select>
        </div>
        <div className="server-card">
          <label>Eşleşme Tipi</label>
          <select className="server-select">
            <option>Solo/Duo</option>
          </select>
        </div>
      </div>
      
      <div className="payment-comments-container">
        <div className="comments-section">
          <h3>Kullanıcı Yorumları</h3>
          <div className="comment-card">
            <div className="comment-header">
              <img src="trustpilot.png" alt="Trustpilot" />
              <p>Trustpilot'da ki 1,243 yorumu görüntüle!</p>
            </div>
            <div className="comment-content">
              <p>z*****</p>
              <p>11.06.2024</p>
              <p>Hesabım platin seviyesindeydi ve çok kısa sürede radiant’a kastılar eloboost sistemleri çok hızlı</p>
              <div className="comment-stars">
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
                <span>★</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="payment-section">
          <div className="payment-card">
            <label>İndirim Kuponu</label>
            <input type="text" placeholder="AWL10" />
          </div>
          <div className="payment-card">
            <label>Toplam Ücret</label>
            <p>60,25₺</p>
          </div>
          <button className="payment-button">Boostlamayı Öde</button>
        </div>
      </div>

      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default BoostingPage;
