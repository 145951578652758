import React, { useState } from 'react';
import '../componentsStyles/FAQCards.css'; // Stil dosyasını burada tanımlayacağız

const FAQCards = () => {
  // Soru ve cevaplar
  const faqs = [
    { question: 'Soru 1', answer: "Eloboost  birçok çevrimiçi oyuncunun tercih ettiği bir hizmettir. Bu hizmet, oyuncuların hesaplarının daha yüksek bir lig veya seviyeye yükseltilmesini sağlar. İşte Lig Yükseltme'nin en önemli avantajları: Daha Yüksek Lig Seviyesi: Türkiye'nin Eloboost Hizmeti olarak, oyuncuların daha yüksek bir lig seviyesine hızlı bir şekilde yükselmesine olanak tanır. Bu, oyuncuların daha rekabetçi bir oyunda daha iyi performans göstermelerine ve daha fazla ödül kazanmalarına yardımcı olabilir. Zaman Tasarrufu: lol boost , oyuncuların oyun oynamak için harcadıkları zamanı azaltır. Bu sayede, oyuncular diğer işlerine daha fazla zaman ayırabilirler. Ödüller: Daha yüksek bir lig seviyesinde oynamak, oyunculara daha iyi ödüller kazandırabilir. Bu ödüller, oyuncuların oyun içinde daha iyi donanımlar satın almalarına ve daha iyi bir oyuncu olmalarına yardımcı olabilir. Güvenlik: Kaliteli elo boost sağlayıcıları, oyuncuların hesaplarının güvenliğini sağlamak için çeşitli önlemler alırlar. Bu sayede, oyuncular hesaplarının güvenli olduğunu bilirler. Sonuç olarak, lol boost, oyuncuların daha yüksek bir lig seviyesine hızlı bir şekilde yükselmelerine ve daha iyi ödüller kazanmalarına yardımcı olan bir hizmettir. Ayrıca, kaliteli boost sitesi sağlayıcıları, oyuncuların hesaplarının güvenliğini sağlarlar." },
    { question: 'Soru 2', answer: 'Cevap 2 uzun cevap metni buraya gelir.' },
    { question: 'Soru 3', answer: 'Cevap 3 uzun cevap metni buraya gelir.' },
    { question: 'Soru 4', answer: 'Cevap 4 uzun cevap metni buraya gelir.' },
    { question: 'Soru 5', answer: 'Cevap 5 uzun cevap metni buraya gelir.' },
    { question: 'Soru 6', answer: 'Cevap 6 uzun cevap metni buraya gelir.' },
  ];

  // Aktif olan soruyu tutan state
  const [activeIndex, setActiveIndex] = useState(null);

  // Bir soruyu açma/kapatma işlevi
  const toggleFAQ = index => {
    if (activeIndex === index) {
      // Aynı soru seçildiyse, kapat
      setActiveIndex(null);
    } else {
      // Yeni bir soru aç
      setActiveIndex(index);
    }
  };

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${activeIndex === index ? 'active' : ''}`}
        >
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            {faq.question}
          </div>
          <div className="faq-answer">
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQCards;
