import React from 'react';
import '../componentsStyles/DifferenceCards.css';

const DifferenceCards = () => {
  return (
    <div className="difference-cards-wrapper">
      <div className="difference-cards-column">
        <div className="difference-card-1">
          <h3>Özellik 1</h3>
          <p>Kısa açıklama 1 burada olacak.</p>
        </div>
        <div className="difference-card-2">
          <h3>Özellik 2</h3>
          <p>Kısa açıklama 2 burada olacak.</p>
        </div>
        <div className="difference-card-3">
          <h3>Özellik 3</h3>
          <p>Kısa açıklama 3 burada olacak.</p>
        </div>
      </div>
      <div className="difference-cards-column">
        <div className="difference-card-4">
          <h3>Özellik 4</h3>
          <p>Kısa açıklama 4 burada olacak.</p>
        </div>
        <div className="difference-card-5">
          <h3>Özellik 5</h3>
          <p>Kısa açıklama 5 burada olacak.</p>
        </div>
        <div className="difference-card-6">
          <h3>Özellik 6</h3>
          <p>Kısa açıklama 6 burada olacak.</p>
        </div>
      </div>
    </div>
  );
};

export default DifferenceCards;
