import React from 'react';
import '../componentsStyles/MainPageCommentCard.css';
import commentImage from '../assets/images/circle1.jpg'; // Örnek görsel
import starIcon from '../assets/images/star-icon.png'; // Yıldız ikonunun görseli
import visual1 from '../assets/images/visual1.png'; // Görsel 1
import visual2 from '../assets/images/visual2.png'; // Görsel 2

const MainPageCommentCard = () => {
  return (
    <div className="comment-container">
    <div className="comment-card">
      <div className="comment-card-content">
        <div className="comment-header">
          <div className="comment-avatar">
            <img src={commentImage} alt="Avatar" />
          </div>
          <div className="comment-text">
            <h2>NickName</h2>
            <p>Yapılan yorum burada olacak.</p>
          </div>
        </div>
        <div className="comment-images">
          <img src={visual1} alt="Visual 1" />
          <span>&gt;</span>
          <img src={visual2} alt="Visual 2" />
        </div>
        <div className="comment-stars">
          {[...Array(5)].map((_, index) => (
            <img key={index} src={starIcon} alt={`Star ${index + 1}`} />
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default MainPageCommentCard;
