// src/components/Header.js
import React, { useState } from 'react';
import '../componentsStyles/Header.css';
import headerVideo from '../assets/videos/header-video.mp4';
import { Link } from 'react-router-dom'; // Link bileşenini içe aktarın
import { FaFacebook, FaTwitter, FaInstagram, FaBlog, FaLifeRing, FaBuilding, FaGamepad, FaTrophy, FaUserCircle, FaStore, FaCog } from 'react-icons/fa';
import LoginSignupModal from '../components/Login';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <header className="header-container">
      <div className="video-container">
        <video src={headerVideo} autoPlay loop muted></video>
      </div>

      <div className="social-media-header">
        <div className="social-media-icons">
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          <span>Sosyal medya hesaplarımız</span>
        </div>
        <div className="right-links">
          <a href="#blog"><FaBlog /> Blog</a>
          <div className="dropdown">
            <a href="#support"><FaLifeRing /> Destek Merkezi</a>
            <div className="dropdown-content">
              <Link to="/boostingPage">Link 1</Link> {/* Link'i güncelleyin */}
              <a href="#link2">Link 2</a>
              <a href="#link3">Link 3</a>
            </div>
          </div>
          <div className="dropdown">
            <a href="#corporate"><FaBuilding /> Kurumsal</a>
            <div className="dropdown-content">
              <a href="#link1">Link 1</a>
              <a href="#link2">Link 2</a>
              <a href="#link3">Link 3</a>
            </div>
          </div>
          <button className="login-signup-button" onClick={handleModalToggle}>Giriş Yap / Üye Ol</button>
        </div>
      </div>

      <div className="main-header">
        <h1>Header Title</h1>
      </div>

      <div className="dropdown-menus-container">
        <div className="dropdown-menus">
          <div className="dropdown">
            <button className="dropbtn"><FaGamepad /> Menu 1</button>
            <div className="dropdown-content">
              <a href="#asd">Link 1</a>
              <a href="#asd">Link 2</a>
              <a href="#asd">Link 3</a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn"><FaGamepad /> Menu 2</button>
            <div className="dropdown-content">
              <a href="#asd">Link 1</a>
              <a href="#asd">Link 2</a>
              <a href="#asd">Link 3</a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn"><FaTrophy /> Menu 3</button>
            <div className="dropdown-content">
              <a href="#asd">Link 1</a>
              <a href="#asd">Link 2</a>
              <a href="#asd">Link 3</a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn"><FaUserCircle /> Menu 4</button>
            <div className="dropdown-content">
              <a href="#asd">Link 1</a>
              <a href="#asd">Link 2</a>
              <a href="#asd">Link 3</a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn"><FaStore /> Menu 5</button>
            <div className="dropdown-content">
              <a href="#asd">Link 1</a>
              <a href="#asd">Link 2</a>
              <a href="#asd">Link 3</a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn"><FaCog /> Menu 6</button>
            <div className="dropdown-content">
              <a href="#asd">Link 1</a>
              <a href="#asd">Link 2</a>
              <a href="#asd">Link 3</a>
            </div>
          </div>
        </div>
      </div>

      <LoginSignupModal isOpen={isModalOpen} onClose={handleModalToggle} />
    </header>
  );
};

export default Header;
