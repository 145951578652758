import React from 'react';
import Header from '../components/Header';
import Slider from 'react-slick';
import '../pagesStyles/HomePage.css';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import sliderBG1 from '../assets/images/banner1.png';
import sliderBG2 from '../assets/images/banner2.png';
import sliderBG3 from '../assets/images/banner3.png';
import sliderBG4 from '../assets/images/banner4.png';
import sliderBG5 from '../assets/images/banner5.png';
import Guvenlik from '../assets/images/guvenlik.jpg';
import { FaCheckCircle } from 'react-icons/fa'; // İkon import
import circleImage1 from '../assets/images/circle1.jpg';
import MainPageCommentCard from '../components/MainPageCommentCard';
import trustpilotImage from '../assets/images/trustpilot.png'; // Trustpilot görseli
import DifferenceCards from '../components/DifferenceCards'; // Yeni eklenen import
import FAQCards from '../components/FAQCards'; // Yeni eklenen import
import Footer from '../components/Footer'; // Yeni eklenen import


const HomePage = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="homepage-container">
            <Header />
            <div className="slider-container">
                <Slider {...settings}>
                    <div>
                        <img src={sliderBG1} alt="Slide 1" />
                    </div>
                    <div>
                        <img src={sliderBG2} alt="Slide 2" />
                    </div>
                    <div>
                        <img src={sliderBG3} alt="Slide 3" />
                    </div>
                    <div>
                        <img src={sliderBG4} alt="Slide 4" />
                    </div>
                    <div>
                        <img src={sliderBG5} alt="Slide 5" />
                    </div>
                </Slider>
            </div>
            <div className="info-section">
                <h1>Rakamlarla AWLEloboost</h1>
                <p>AWL Eloboost olarak, oyuncularımıza sunduğumuz kaliteli hizmetler ve müşteri memnuniyeti odaklı yaklaşımımız sayesinde sektörün öncü isimlerinden biri olmayı başardık.</p>
            </div>
            <div className="circles-section">
                <div className="circle-item">
                    <div className="circle">
                        <img src={circleImage1} alt="Circle 1" />
                    </div>
                    <div className="circle-text">
                        <h1>450</h1>
                        <p>Kısa Açıklama 1</p>
                    </div>
                </div>
                <div className="circle-item">
                    <div className="circle">
                        <img src={circleImage1} alt="Circle 2" />
                    </div>
                    <div className="circle-text">
                        <h1>300</h1>
                        <p>Kısa Açıklama 2</p>
                    </div>
                </div>
                <div className="circle-item">
                    <div className="circle">
                        <img src={circleImage1} alt="Circle 3" />
                    </div>
                    <div className="circle-text">
                        <h1>150</h1>
                        <p>Kısa Açıklama 3</p>
                    </div>
                </div>
                <div className="circle-item">
                    <div className="circle">
                        <img src={circleImage1} alt="Circle 4" />
                    </div>
                    <div className="circle-text">
                        <h1>75</h1>
                        <p>Kısa Açıklama 4</p>
                    </div>
                </div>
            </div>
            <div className="reviews-section">
                <h1>Kullanıcı Yorumları</h1>
                <p>AWL Eloboost olarak bizimle çalışan kişilerin yorumları</p>
                <div className="trustpilot">
                    <img src={trustpilotImage} alt="Trustpilot" />
                    <p>1.243 yorumu görüntüle!</p>
                </div>
                <div className="comment-cards-container">
                    {[...Array(3)].map((_, index) => (
                        <MainPageCommentCard key={index} />
                    ))}
                </div>
            </div>
            <div className="diffrence-h1">
                <h1>Bizi Diğerlerinden Ayıran Nedir?</h1>
            </div>
            <div className="difference-section">
                <DifferenceCards />
            </div>
            <div className="guvenlik-h1">
                <h1>Güvenilir sistemlerimiz!</h1>
            </div>
            <div className="guvenlik-section">
                <div className="guvenlik-info">
                    <ul className="guvenlik-list">
                        <li>
                            <FaCheckCircle className="icon" />
                            <div>
                                <p>Güvenli Ödeme Sistemi</p>
                                <span>Güvenli ödeme sistemimiz ile ödemeniz her zaman güvende.</span>
                            </div>
                        </li>
                        <li>
                            <FaCheckCircle className="icon" />
                            <div>
                                <p>7/24 Destek Hizmeti</p>
                                <span>Her zaman yanınızdayız, 7 gün 24 saat hizmetinizdeyiz.</span>
                            </div>
                        </li>
                        <li>
                            <FaCheckCircle className="icon" />
                            <div>
                                <h2>%100 Müşteri Memnuniyeti</h2>
                                <p>Müşterilerimizin memnuniyeti bizim için her şeyden önemli.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <img src={Guvenlik} alt="security" />
            </div>
            <div className="system-h1">
                <h1>Sıkça Sorulan Sorular</h1>
            </div>
            <div className="systems-section">
                <FAQCards />
            </div>
            <div className="footer">

            <Footer />

            </div>
        </div>
    );
};

export default HomePage;
